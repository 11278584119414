<template>
  <div>
    <search-form>
      <div class="d-inline-flex flex-wrap">
        <c-input v-model="search.id" label="ID" search-form />
        <c-input v-model="search.name" :label="$t('shared.name')" search-form />
        <c-input v-model="search.superAdmin" :items="$booleanOptions" :label="$t('roles.superAdmin')" select search-form />
      </div>

      <div class="ma-1">
        <v-btn color="success" dark @click="filtering" small :loading="progressing">
          <v-icon small class="me-1"> mdi-filter </v-icon>
          {{ $t('shared.applyFilter') }}
        </v-btn>
      </div>
    </search-form>

    <div class="d-flex mb-4">
      <v-spacer />
      <v-btn color="success" dark to="create" small>
        <v-icon small> mdi-plus </v-icon>
        {{ $t('shared.add') }}
      </v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="context"
      @update:options="filtering"
      :server-items-length="count"
      :loading="progressing"
      :footer-props="$config.tableFooterProps"
      sort-by="id"
      must-sort
      class="elevation-2"
    >
      <template #[`item.actions`]="{item}">
        <div v-if="item.id > 0" class="d-flex justify-end">
          <v-btn small fab text color="info" :to="`edit/${item.id}`">
            <v-icon> mdi-eye </v-icon>
          </v-btn>

          <v-btn small fab text color="error" @click="removeItem(item)">
            <v-icon> mdi-delete </v-icon>
          </v-btn>
        </div>
      </template>

      <template #[`item.superAdmin`]="{item}">
        <v-icon v-if="item.superAdmin" color="success"> mdi-check </v-icon>
        <v-icon v-else color="secondary"> mdi-close </v-icon>
      </template>

      <template v-slot:no-data>
        {{ $t('shared.noRecords') }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Index',
  props: {
    branchId: {type: [String, Number], default: null}
  },
  data() {
    return {
      progressing: false,
      count: 1,
      context: {},
      items: [],
      headers: [
        {text: 'ID', value: 'id'},
        {text: this.$t('shared.name'), value: 'name'},
        {text: this.$t('roles.superAdmin'), value: 'superAdmin'},
        {text: this.$t('shared.actions'), value: 'actions', sortable: false}
      ],
      search: {
        id: null,
        name: null,
        superAdmin: null
      }
    }
  },
  methods: {
    filter(isCount) {
      const filter = this.$filter()

      if (!isCount) {
        filter['sort[0].column'] = this.context.sortBy[0]
        filter['sort[0].type'] = this.context.sortDesc[0] ? 'ASCENDING' : 'DESCENDING'
        filter.size = this.context.itemsPerPage
        filter.page = this.context.page
      }

      return filter
    },
    getItems(filter) {
      return this.$api.org.roles.getAll(filter, this.type).then((res) => {
        this.items = res
      })
    },
    getCount(filter) {
      return this.$api.org.roles.count(filter, this.type).then((res) => {
        this.count = res
      })
    },
    filtering() {
      this.progressing = true
      Promise.all([this.getItems(this.filter()), this.getCount(this.filter(true))])
        .then(() => {
          this.progressing = false
        })
        .catch((err) => {
          this.$showError(err)
        })
    },
    async removeItem(item) {
      try {
        if (!(await this.$confirm())) return

        await this.$api.org.roles.delete(item.id)
        this.filtering()
        this.$showSuccess(this.$t('roles.roleRemoved'))
      } catch (error) {
        this.$showError(error)
      }
    }
  }
}
</script>
